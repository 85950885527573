import React from 'react';
import Helmet from 'react-helmet';
import { Header, Grid, Item, Label, List, Image, Divider } from 'semantic-ui-react';

import '../style/how-it-works.css';
import Layout from '../components/Layout';

export default ({ data, location }) => {
  return (
    <Layout>
      <Helmet
        title={`Full Scope Web | How It Works`}
        meta={[
          { name: 'description', content: 'See how Full Scope Web\'s design and development works.' },
        ]}
      />
      <Grid className="how-it-works" stackable divided>
        <Grid.Column>
          <h3>How It Works</h3>
          <h4>Phase 1:   Scoping - The goal for this phase is to determine project requirements and timeline.</h4>
          <ol>
            <li>
              <a href="https://calendly.com/fullscopeweb/scoping" target="_blank" style={{ color: 'inherit', textDecoration: 'underline' }}>
                Schedule an introductory call
              </a>
              {' '} with a Full Scope Web specialist to go over project goals and get a rough price quote.</li>
            <li>We’ll send you a Typeform to answer preliminary branding questions and submit content for your website. </li>
          </ol>
          <h4>Phase 2: Design - There are 3 iterations of design feedback before we begin implementation.</h4>
          <ol>
            <li>First, we send a rough mock-up to confirm alignment on branding and style direction.</li>
            <li>Then we send a more fleshed out desktop wireframe.</li>
            <li>Finally, we send high-res designs of mobile & desktop versions for final approval</li>
          </ol>
          <h4>Phase 3: Implementation - We bring your idea to life.</h4>
          <ol>
            <li>We code the design using a modern tech stack of React + AWS or build the project using a flexible CMS.</li>
            <li>Based on requests, we build custom features for your project.</li>
            <li>We tie in a content editor, analytics, and basic SEO. Once the site is complete, we provide a tutorial for how to make updates to your website.</li>
          </ol>
        </Grid.Column>
      </Grid>
    </Layout>
  );
};
